import mapIcon from '../resources/images/gps_map_icon_c.png';

// homeLocations map

var homeLocations = document.querySelectorAll('.homemapcolleft');
var homelocationinnerwrap = document.querySelectorAll('.homelocationinnerwrap');
var backtolist = document.querySelectorAll('.backtolist');
let curLoc = '';

if (homeLocations.length > 0) {


    mapboxgl.accessToken =
        "pk.eyJ1IjoidXBwZXJob3VzZSIsImEiOiJjazFpOHdzanoxaW5zM21wZGx2ZHRqM2R4In0.Ahjx7eaAqipeYaYLYCWjZA";
    const map = new mapboxgl.Map({
        container: "map",
        // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
        style: "mapbox://styles/mapbox/light-v11",
        center: [-117.906454, 33.654739],
        zoom: 8.8,
        interactive: false,
    });
    map.scrollZoom.disable();


    map.on("load", () => {

        backtolist.forEach(el => el.addEventListener('click', event => {
            event.preventDefault()
            homelocationinnerwrap[0].classList.remove("active");
            map.setPaintProperty('polygon1', 'fill-opacity', 0);
            map.setPaintProperty('polygon2', 'fill-opacity', 0);
            map.setPaintProperty('polygon3', 'fill-opacity', 0);
            map.setPaintProperty('polygon4', 'fill-opacity', 0);
            map.setPaintProperty('polygon5', 'fill-opacity', 0);
            map.setPaintProperty('polygon6', 'fill-opacity', 0);
        }));

    });


    var homemapinview = document.querySelectorAll('.homemap');

    let homeMapPos = mapIsScrolledIntoView(homemapinview);
    let initHomeMapScroll = false

    if (homeMapPos == true) {

       // loadHomeMap(map);

    } else {

        window.addEventListener('scroll', function(e) {
            let watchHomeMapPos = mapIsScrolledIntoView(homemapinview);

            if (watchHomeMapPos == true && initHomeMapScroll == false) {
                initHomeMapScroll = true

               // loadHomeMap(map);

            }

        }, false);
    }

    function loadHomeMap(map) {
        console.log('load map');

        // Dana Point
        const locOnePointLatLng = [-117.7231871, 33.4943349];

        function locationOne() {
            map.loadImage(mapIcon, (error, image) => {
                if (error) throw error;
                map.addImage("custom-marker1", image);
                // Add a GeoJSON source with 2 points
                map.addSource("points1", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [{
                            // feature for Dana Point
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: locOnePointLatLng,
                            },
                        }, ],
                    },
                });

                // Add a symbol layer
                map.addLayer({
                    id: "points1",
                    type: "symbol",
                    source: "points1",
                    layout: {
                        "icon-image": "custom-marker1",
                        // get the title name from the source's "title" property
                        "text-field": ["get", "title"],
                        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                        "text-offset": [0, 1.25],
                        "text-anchor": "top",
                    },
                });
            });

            map.addSource(
                "polygon1",
                createGeoJSONCircle(locOnePointLatLng, 30.0)
            );

            map.addLayer({
                id: "polygon1",
                type: "fill",
                source: "polygon1",
                layout: {},
                paint: {
                    "fill-color": "#34658d",
                    "fill-opacity": 0,
                },
            });

            let locone = document.querySelector(".locone");
            setTimeout(function() {
                locone.classList.add("active");
            }, 200);

            setTimeout(function() {
                locone.classList.remove("active");
            }, 800);

        }

        // Huntington Beach
        const locTwoPointLatLng = [-117.9928712, 33.7014531];

        function locationTwo() {
            map.loadImage(mapIcon, (error, image) => {
                if (error) throw error;
                map.addImage("custom-marker2", image);
                // Add a GeoJSON source with 2 points
                map.addSource("points2", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [{
                            // feature for Huntington Beach
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: locTwoPointLatLng,
                            },
                        }, ],
                    },
                });

                // Add a symbol layer
                map.addLayer({
                    id: "points2",
                    type: "symbol",
                    source: "points2",
                    layout: {
                        "icon-image": "custom-marker2",
                        // get the title name from the source's "title" property
                        "text-field": ["get", "title"],
                        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                        "text-offset": [0, 1.25],
                        "text-anchor": "top",
                    },
                });
            });

            map.addSource(
                "polygon2",
                createGeoJSONCircle(locTwoPointLatLng, 30.0)
            );

            map.addLayer({
                id: "polygon2",
                type: "fill",
                source: "polygon2",
                layout: {},
                paint: {
                    "fill-color": "#69b6bc",
                    "fill-opacity": 0,
                },
            });

            let loctwo = document.querySelector(".loctwo");
            setTimeout(function() {
                loctwo.classList.add("active");
            }, 200);

            setTimeout(function() {
                loctwo.classList.remove("active");
            }, 800);

        }

        // Riverside
        const locThreePointLatLng = [-117.401028, 33.9493029];

        function locationThree() {
            map.loadImage(mapIcon, (error, image) => {
                if (error) throw error;
                map.addImage("custom-marker3", image);
                // Add a GeoJSON source with 2 points
                map.addSource("points3", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [{
                            // feature for Huntington Beach
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: locThreePointLatLng,
                            },
                        }, ],
                    },
                });

                // Add a symbol layer
                map.addLayer({
                    id: "points3",
                    type: "symbol",
                    source: "points3",
                    layout: {
                        "icon-image": "custom-marker3",
                        // get the title name from the source's "title" property
                        "text-field": ["get", "title"],
                        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                        "text-offset": [0, 1.25],
                        "text-anchor": "top",
                    },
                });
            });

            map.addSource(
                "polygon3",
                createGeoJSONCircle(locThreePointLatLng, 30.0)
            );

            map.addLayer({
                id: "polygon3",
                type: "fill",
                source: "polygon3",
                layout: {},
                paint: {
                    "fill-color": "#a1d9d2",
                    "fill-opacity": 0,
                },
            });

            let locthree = document.querySelector(".locthree");
            setTimeout(function() {
                locthree.classList.add("active");
            }, 200);

            setTimeout(function() {
                locthree.classList.remove("active");
            }, 800);

        }

         // Valley
        const locFourPointLatLng = [-118.5294178, 34.2007831];

        function locationFour() {
            map.loadImage(mapIcon, (error, image) => {
                if (error) throw error;
                map.addImage("custom-marker4", image);
                // Add a GeoJSON source with 2 points
                map.addSource("points4", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [{
                            // feature for Beverly Hills
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: locFourPointLatLng,
                            },
                        }, ],
                    },
                });

                // Add a symbol layer
                map.addLayer({
                    id: "points4",
                    type: "symbol",
                    source: "points4",
                    layout: {
                        "icon-image": "custom-marker4",
                        // get the title name from the source's "title" property
                        "text-field": ["get", "title"],
                        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                        "text-offset": [0, 1.25],
                        "text-anchor": "top",
                    },
                });
            });

            map.addSource(
                "polygon4",
                createGeoJSONCircle(locFourPointLatLng, 30.0)
            );

            map.addLayer({
                id: "polygon4",
                type: "fill",
                source: "polygon4",
                layout: {},
                paint: {
                    "fill-color": "#34658d",
                    "fill-opacity": 0,
                },
            });

            let locfour = document.querySelector(".locfour");
            setTimeout(function() {
                locfour.classList.add("active");
            }, 200);

            setTimeout(function() {
                locfour.classList.remove("active");
            }, 800);

        }

         // San Gabriel
        const locFivePointLatLng = [-117.9261074, 34.071511];

        function locationFive() {
            map.loadImage(mapIcon, (error, image) => {
                if (error) throw error;
                map.addImage("custom-marker5", image);
                // Add a GeoJSON source with 2 points
                map.addSource("points5", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [{
                            // feature for Beverly Hills
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: locFivePointLatLng,
                            },
                        }, ],
                    },
                });

                // Add a symbol layer
                map.addLayer({
                    id: "points5",
                    type: "symbol",
                    source: "points5",
                    layout: {
                        "icon-image": "custom-marker5",
                        // get the title name from the source's "title" property
                        "text-field": ["get", "title"],
                        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                        "text-offset": [0, 1.25],
                        "text-anchor": "top",
                    },
                });
            });

            map.addSource(
                "polygon5",
                createGeoJSONCircle(locFivePointLatLng, 30.0)
            );

            map.addLayer({
                id: "polygon5",
                type: "fill",
                source: "polygon5",
                layout: {},
                paint: {
                    "fill-color": "#69b6bc",
                    "fill-opacity": 0,
                },
            });

            let locfive = document.querySelector(".locfive");
            setTimeout(function() {
                locfive.classList.add("active");
            }, 200);

            setTimeout(function() {
                locfive.classList.remove("active");
            }, 800);

        }

         // Antelope Valley
         const locSixPointLatLng = [-118.1469859, 34.5910663];

         function locationSix() {
            console.log('locationSix');
             map.loadImage(mapIcon, (error, image) => {
                 if (error) throw error;
                 map.addImage("custom-marker6", image);
                 // Add a GeoJSON source with 2 points
                 map.addSource("points6", {
                     type: "geojson",
                     data: {
                         type: "FeatureCollection",
                         features: [{
                             // feature for Beverly Hills
                             type: "Feature",
                             geometry: {
                                 type: "Point",
                                 coordinates: locSixPointLatLng,
                             },
                         }, ],
                     },
                 });

                 // Add a symbol layer
                 map.addLayer({
                     id: "points6",
                     type: "symbol",
                     source: "points6",
                     layout: {
                         "icon-image": "custom-marker6",
                         // get the title name from the source's "title" property
                         "text-field": ["get", "title"],
                         "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                         "text-offset": [0, 1.25],
                         "text-anchor": "top",
                     },
                 });
             });

             map.addSource(
                 "polygon6",
                 createGeoJSONCircle(locSixPointLatLng, 30.0)
             );
 
             map.addLayer({
                 id: "polygon6",
                 type: "fill",
                 source: "polygon6",
                 layout: {},
                 paint: {
                     "fill-color": "#69b6bc",
                     "fill-opacity": 0,
                 },
             });

             let locsix = document.querySelector(".locsix");
             setTimeout(function() {
                 locsix.classList.add("active");
             }, 200);

             setTimeout(function() {
                 locsix.classList.remove("active");
             }, 800);

         }

        function placeMarkers() {
            let locinterval
            let locindx = 1

            setTimeout(() => {
                // call your func here
                locinterval = setInterval(() => {
                    if (locindx == 1) {
                        locationOne()
                    }

                    if (locindx == 2) {
                        locationTwo()
                    }

                    if (locindx == 3) {
                        locationThree()
                    }

                    if (locindx == 4) {
                        locationFour()
                    }

                    if (locindx == 5) {
                        locationFive()
                    }

                    if (locindx == 6) {
                        locationSix()
                    }

                    locindx++
                    if (locindx >= 7) {
                        clearInterval(locinterval);

                        var bounds = new mapboxgl.LngLatBounds();
                        bounds.extend(locOnePointLatLng);
                        bounds.extend(locTwoPointLatLng);
                        bounds.extend(locThreePointLatLng);
                        bounds.extend(locFourPointLatLng);
                        bounds.extend(locFivePointLatLng);
                        bounds.extend(locSixPointLatLng);

                       map.fitBounds(bounds, { padding: 30 });
                    }
                        
                }, 400)
            }, 0)
        }

        placeMarkers()

    }

    var createGeoJSONCircle = function(center, radiusInKm, points) {
        if (!points) points = 64;

        var coords = {
            latitude: center[1],
            longitude: center[0],
        };

        var km = radiusInKm;

        var ret = [];
        var distanceX =
            km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
        var distanceY = km / 110.574;

        var theta, x, y;
        for (var i = 0; i < points; i++) {
            theta = (i / points) * (2 * Math.PI);
            x = distanceX * Math.cos(theta);
            y = distanceY * Math.sin(theta);

            ret.push([coords.longitude + x, coords.latitude + y]);
        }
        ret.push(ret[0]);

        return {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [{
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [ret],
                    },
                }, ],
            },
        };
    };

    // home locations carousel

    document.getElementById("locone").addEventListener("click", (e) => {

        // feature for Dana Point
        map.setPaintProperty('polygon1', 'fill-opacity', 0.35);
        map.setPaintProperty('polygon2', 'fill-opacity', 0);
        map.setPaintProperty('polygon3', 'fill-opacity', 0);
        map.setPaintProperty('polygon4', 'fill-opacity', 0);
        map.setPaintProperty('polygon5', 'fill-opacity', 0);
        map.setPaintProperty('polygon6', 'fill-opacity', 0);

        curLoc = 'polygon1';
       homelocationinnerwrap[0].classList.add("active");
    });

    document.getElementById("loctwo").addEventListener("click", (e) => {

        // feature for Riverside
        
        map.setPaintProperty('polygon1', 'fill-opacity', 0);
        map.setPaintProperty('polygon2', 'fill-opacity', 0.35);
        map.setPaintProperty('polygon3', 'fill-opacity', 0);
        map.setPaintProperty('polygon4', 'fill-opacity', 0);
        map.setPaintProperty('polygon5', 'fill-opacity', 0);
        map.setPaintProperty('polygon6', 'fill-opacity', 0);

        curLoc = 'polygon2';
       homelocationinnerwrap[0].classList.add("active");

    });

    document.getElementById("locthree").addEventListener("click", (e) => {

        // feature for Huntington Beach
        
        map.setPaintProperty('polygon1', 'fill-opacity', 0);
        map.setPaintProperty('polygon2', 'fill-opacity', 0);
        map.setPaintProperty('polygon3', 'fill-opacity', 0.35);
        map.setPaintProperty('polygon4', 'fill-opacity', 0);
        map.setPaintProperty('polygon5', 'fill-opacity', 0);
        map.setPaintProperty('polygon6', 'fill-opacity', 0);

        curLoc = 'polygon3';
       homelocationinnerwrap[0].classList.add("active");

    });

    document.getElementById("locfour").addEventListener("click", (e) => {

        // feature for Beverly Hills

        map.setPaintProperty('polygon1', 'fill-opacity', 0);
        map.setPaintProperty('polygon2', 'fill-opacity', 0);
        map.setPaintProperty('polygon3', 'fill-opacity', 0);
        map.setPaintProperty('polygon4', 'fill-opacity', 0.35);
        map.setPaintProperty('polygon5', 'fill-opacity', 0);
        map.setPaintProperty('polygon6', 'fill-opacity', 0);

        curLoc = 'polygon4';
       homelocationinnerwrap[0].classList.add("active");

    });

    document.getElementById("locfive").addEventListener("click", (e) => {

        // feature for Beverly Hills

        map.setPaintProperty('polygon1', 'fill-opacity', 0);
        map.setPaintProperty('polygon2', 'fill-opacity', 0);
        map.setPaintProperty('polygon3', 'fill-opacity', 0);
        map.setPaintProperty('polygon4', 'fill-opacity', 0);
        map.setPaintProperty('polygon5', 'fill-opacity', 0.35);
        map.setPaintProperty('polygon6', 'fill-opacity', 0);

        curLoc = 'polygon5';
       homelocationinnerwrap[0].classList.add("active");

    });

    document.getElementById("locsix").addEventListener("click", (e) => {

        // feature for Palmdale

        map.setPaintProperty('polygon1', 'fill-opacity', 0);
        map.setPaintProperty('polygon2', 'fill-opacity', 0);
        map.setPaintProperty('polygon3', 'fill-opacity', 0);
        map.setPaintProperty('polygon4', 'fill-opacity', 0);
        map.setPaintProperty('polygon5', 'fill-opacity', 0);
        map.setPaintProperty('polygon6', 'fill-opacity', 0.35);

        curLoc = 'polygon6';
       homelocationinnerwrap[0].classList.add("active");

    });


        let homelocationsheight = jQuery(".homelocations").outerHeight()
        let box = document.querySelector('.homelocationsplide');
        let locrect = box.getBoundingClientRect();
        let locheight = 800;

        jQuery('.homemap').css({"height": locheight});
        loadHomeMap(map);
        map.on('load', function () {
            map.resize();
        });


};

// check if element is in view
function mapIsScrolledIntoView(elem) {

    // const el = element[0];
    // const r = el.getBoundingClientRect();
    // const vw = document.documentElement.clientWidth;
    // const vh = document.documentElement.clientHeight;
    // const inViewX = (r.left > 0 && r.left < vw) || (r.right < vw && r.right > 0);
    // const inViewY = (r.top > 0 && r.top < vh) || (r.bottom < vh && r.bottom > 0);
    // return inViewX && inViewY;

    var docViewTop = jQuery(window).scrollTop();
    var docViewBottom = docViewTop + jQuery(window).height() + 500;

    var elemTop = jQuery(elem).offset().top;
    var elemBottom = elemTop + jQuery(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}